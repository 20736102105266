<template>
  <b-card-code no-body title="Auction Request List">

    <div class="dt_adv_search ml-1 mb-1 mr-1">
      <div class="row">

        <div class="col-3">
          <select class="form-control form-control-sm" v-model="filter.status">
            <option selected value="">-- All Status --</option>
            <option value="pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>

      </div>
    </div>

    <div class="table-responsive-sm">
      <table class="table b-table">
        <thead>
          <tr>
            <th>
              Seller
            </th>
            <th>
              Asset &amp; Warehouse
            </th>
            <th>
              Price
            </th>
            <th>
              Qty
            </th>
            <th>
              Time
            </th>
            <th>
              Status
            </th>
            <th>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td colspan="7" class="text-center">
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <template v-else v-for="item in result.data">
            <tr :key="item.uuid">
              <td>
                <router-link :to="{ name: 'users.show', params: { id: item.user.id } }">
                    {{ item.user.code }}<br><small>{{ item.user.email }}</small>
                </router-link>
              </td>
              <td>
                {{ item.commodity.code }}<br>{{ item.warehouse.name }}
              </td>
              <td style="width: 350px;">
                Starting: <strong>{{ item.starting_price_formatted }}</strong><br>
                Minimum: <strong>{{ item.minimum_price_formatted }}</strong><br>
                Buy Now: <strong>{{ item.buy_sell_now_price_formatted }}</strong><br>
              </td>
              <td style="width: 350px;">
                Lot to Trade: <strong>{{ item.lot_to_trade }} lot</strong><br>
                Minimum Bid: <strong>{{ item.minimum_lot }} lot</strong><br>
              </td>
              <td style="width: 350px;">
                Start: <strong>{{ item.start_time }}</strong><br>
                End: <strong>{{ item.end_time }}</strong><br>
              </td>
              <td class="text-capitalize">
                <span class="badge text-capitalize badge-pill" v-bind:class="getStatusColor(item.status)">{{ item.status }}</span>
              </td>
              <td>
                <div class="btn-group" role="group">
                  <button class="btn btn-success btn-sm" v-if="item.status == 'pending' && checkPermission('approve auction request')" @click="detailAuctionRequest(item)" style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Approve">
                    <feather-icon
                      icon="CheckCircleIcon"
                    />
                  </button>
                  <button class="btn btn-danger btn-sm" v-if="item.status == 'pending' && checkPermission('reject auction request')" @click="rejectAuctionRequest(item)" style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Reject">
                    <feather-icon
                      icon="XCircleIcon"
                    />
                  </button>
                </div>
              </td>
            </tr>
          </template>
          <tr v-if="result.total == 0 && !isLoading">
            <td colspan="7" class="text-center">Data is empty.</td>
          </tr>
        </tbody>
      </table>

      <div class="m-1" v-if="result.total > 0">
        <div class="row">
          <div class="col">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }} match</small>
          </div>
          <div class="col">
            <pagination :data="result" @pagination-change-page="getData" :limit="4" align="right"></pagination>
          </div>
        </div>
      </div>
    </div>

    <b-modal no-close-on-backdrop id="detail-modal" title="Approve Auction Request" size="lg">
      
      <table class="table table-hover" v-if="detail != null">
        <tr>
          <td class="ml-0 pl-0">User</td>
          <td v-if="detail.user != null">
            <strong>{{ detail.user.code }}</strong>
            <router-link :to="{ name: 'users.show', params: { id: detail.user.id } }">
              <feather-icon
                icon="ExternalLinkIcon"
              />
            </router-link>
          </td><td v-else>-</td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">Asset</td>
          <td>
            <strong>{{ detail.commodity.code }}</strong>
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">Warehouse</td>
          <td>
            <strong>{{ detail.warehouse.name }}</strong>
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">Price</td>
          <td>
            Starting: <strong>{{ detail.starting_price_formatted }}</strong><br>
            Minimum: <strong>{{ detail.minimum_price_formatted }}</strong><br>
            Buy Now: <strong>{{ detail.buy_sell_now_price_formatted }}</strong><br>
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">Qty</td>
          <td>
            Lot to Trade: <strong>{{ detail.lot_to_trade }} lot</strong><br>
              Minimum Bid: <strong>{{ detail.minimum_lot }} lot</strong><br>
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">Auction Time</td>
          <td>
            Start: <strong>{{ detail.start_time }}</strong><br>
            End: <strong>{{ detail.end_time }}</strong><br>
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">Status</td>
          <td>
            <span class="badge text-capitalize badge-pill" v-bind:class="getStatusColor(detail.status)">{{ detail.status }}</span>
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">Time Submission</td>
          <td>
            <strong>{{ detail.created_at }}</strong>
          </td>
        </tr>
      </table>

      <br>
      <h5>Transaction Fee</h5>

      <div class="row mb-2">
        <div class="col-6">
          <label for="">VAT/PPN:</label>
          <div class="input-group">
            <input type="text" class="form-control" v-model="formPayload.vat_amount">
            <div class="input-group-append">
              <span class="input-group-text">%</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="">Buyer Fee Type:</label>
            <select class="form-control" v-model="formPayload.buyer_fee_type">
              <option v-for="type in priceType" :key="type.key" :value="type.key">{{ type.label }}</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <label for="">Buyer Fee Amount:</label>
          <div class="input-group">
            <div class="input-group-prepend" v-if="['price_per_unit', 'price_per_lot', 'fixed'].includes(formPayload.buyer_fee_type)">
              <span class="input-group-text">{{ currency }}</span>
            </div>
            <input type="text" class="form-control" v-model="formPayload.buyer_fee_amount">
            <div class="input-group-append" v-if="formPayload.buyer_fee_type == 'percent'">
              <span class="input-group-text">%</span>
            </div>
            <div class="input-group-append" v-if="['price_per_unit', 'price_per_lot'].includes(formPayload.buyer_fee_type)">
              <span class="input-group-text" v-if="formPayload.buyer_fee_type == 'price_per_unit'">per unit</span>
              <span class="input-group-text" v-if="formPayload.buyer_fee_type == 'price_per_lot'">per lot</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="">Buyer Fee Payment On:</label>
            <select class="form-control" v-model="formPayload.buyer_fee_payment_on">
              <option v-for="(option, key) in buyerFeePaymentOn" :key="key" :value="key">{{ option.label }}</option>
            </select>
            <small class="text-info" v-if="Object.keys(buyerFeePaymentOn).includes(formPayload.buyer_fee_payment_on)">{{ buyerFeePaymentOn[formPayload.buyer_fee_payment_on].description }}</small>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="">Seller Fee Type:</label>
            <select class="form-control" v-model="formPayload.seller_fee_type">
              <option v-for="type in priceType" :key="type.key" :value="type.key">{{ type.label }}</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <label for="">Seller Fee Amount:</label>
          <div class="input-group">
            <div class="input-group-prepend" v-if="['price_per_unit', 'price_per_lot', 'fixed'].includes(formPayload.seller_fee_type)">
              <span class="input-group-text">{{ currency }}</span>
            </div>
            <input type="text" class="form-control" v-model="formPayload.seller_fee_amount">
            <div class="input-group-append" v-if="formPayload.seller_fee_type == 'percent'">
              <span class="input-group-text">%</span>
            </div>
            <div class="input-group-append" v-if="['price_per_unit', 'price_per_lot'].includes(formPayload.seller_fee_type)">
              <span class="input-group-text" v-if="formPayload.seller_fee_type == 'price_per_unit'">per unit</span>
              <span class="input-group-text" v-if="formPayload.seller_fee_type == 'price_per_lot'">per lot</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="">Seller Fee Payment On:</label>
            <select class="form-control" v-model="formPayload.seller_fee_payment_on">
              <option v-for="(option, key) in sellerFeePaymentOn" :key="key" :value="key">{{ option.label }}</option>
            </select>
            <small class="text-info" v-if="Object.keys(sellerFeePaymentOn).includes(formPayload.seller_fee_payment_on)">{{ sellerFeePaymentOn[formPayload.seller_fee_payment_on].description }}</small>
          </div>
        </div>
      </div>
      <!-- TODO: Disini form -->

      <template #modal-footer="{}" v-if="detail!= null && detail.status == 'pending'">
        <b-button variant="success" @click="approveAuctionRequest()">
          Approve Auction Request
        </b-button>
      </template>
    </b-modal>

  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner } from 'bootstrap-vue'
import { EyeIcon } from 'vue-feather-icons'
import { checkPermission } from '@/auth/utils'

export default {
  title () {
    return `Auction Request List`
  },
  setup() {
    return {
      checkPermission,
    }
  },
  components: {
    BCard,
    BButton,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardCode,
    EyeIcon,
    BSpinner
  },
  watch: {
    filter: {
      handler: _.debounce(function() {                                                                                                                                                              
        this.getData();                                                                                                                                                                        
      }, 200),
      deep: true
    },
  },
  data() {
    return {
      currentPage: 1,
      currency: 'USD',
      filter: {
        type: '',
        status: '',
      },
      result: {},
      isLoading: false,
      detail: null,
      formPayload: Object,
      sellerFeePaymentOn: Object,
      buyerFeePaymentOn: Object,
      priceType: [
        {
          key: 'percent',
          label: 'Percent'
        },
        {
          key: 'fixed',
          label: 'Fixed Price'
        },
        {
          key: 'price_per_unit',
          label: 'Price per Unit'
        },
        {
          key: 'price_per_lot',
          label: 'Price per Lot'
        },
      ],
    }
  },
  created() {
    this.loadFormData()
    this.getData();
  },
  methods: {
    loadFormData() {
      this.$http.get('/admin/miscellaneous/auction-seller-fee-payment-list')
      .then(response => {
        this.sellerFeePaymentOn = response.data.data
      })
      
      this.$http.get('/admin/miscellaneous/auction-buyer-fee-payment-list')
      .then(response => {
        this.buyerFeePaymentOn = response.data.data
      })
    },
    getData(page = 1) {

      this.currentPage = page;
      var queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/auction-request', {
        params: queryParams
      })
      .then(response => {
        this.result = response.data.data
      })

    },
    detailAuctionRequest(item) {
      this.detail = item;
      this.currency = item.commodity.currency_code
      this.$bvModal.show('detail-modal')
      this.formPayload = {
        buyer_fee_type: 'fixed',
        buyer_fee_amount: 0,
        buyer_fee_payment_on: 'on_bid',
        seller_fee_type: 'fixed',
        seller_fee_amount: 0,
        seller_fee_payment_on: 'on_settlement',
        vat_amount: 0,
      };
    },
    approveAuctionRequest(item) {

      this.$swal({
        title: 'Are you sure?',
        text: "System will generate auction and Asset seller will be deducted",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          
          this.$http.post('/admin/auction-request/' + this.detail.uuid + '/approve', this.formPayload)
          .then(response => {
    
            this.getData(this.currentPage)
            this.$bvModal.hide('detail-modal')
            this.$swal({
              icon: 'success',
              title: 'Approved!',
              text: "Auction Request status changed to success.",
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          })
        }
      })

    },
    rejectAuctionRequest(item) {

      this.$swal({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          
          this.$http.post('/admin/auction-request/' + item.uuid + '/reject')
          .then(response => {
    
            this.getData(this.currentPage)
            this.$swal({
              icon: 'success',
              title: 'Rejected!',
              text: "Auction request status changed to rejected/failed.",
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          })
        }
      })

      

    },
    getStatusColor(status) {
      switch (status) {
        case 'approved':
          return 'badge-light-success';
          break;

        case 'pending':
          return 'badge-light-info';
          break;

        case 'rejected':
          return 'badge-light-danger';
          break;
      
        default:
          return 'badge-light-secondary';
          break;
      }
    }
  }
}
</script>